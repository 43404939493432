<template>
  <component-wrapper :layout="layout">
    <container v-for="item in options" :key="item.value"
      >{{ item.label }}
      <radio
        type="radio"
        :value="selectedOption"
        :data-id="`${dataId}_${value}`"
        @click="onInputHandler(item.value)"
        :name="'radioButtons_' + radioName"
        :checked="selectedOption === item.value"
      />
      <span class="checkmark"></span>
    </container>
  </component-wrapper>
</template>

<script>
// To use:
// <radio-button
//     :options="radioOptions"
//     :selectedOption="checkedOption"
//     dataId="test_data_id"
//     @on-radio-change="onInputChange($event)"
//     layout="vertical"
//   />
// Data being passed should look like:
// radioOptions: [
//   { label: 'Button 1', value: 'button_1' },
//   { label: 'Button 2', value: 'button_2' },
//   { label: 'Button 3', value: 'button_3' },
//   { label: 'Button 4', value: 'button_4' }
// ]

import styled from 'vue-styled-components'

const wrapperProps = {
  layout: String
}
const ComponentWrapper = styled('div', wrapperProps)`
  display: flex;
  flex-direction: ${(props) =>
    props.layout === 'vertical' ? 'column' : 'row'};
  grid-gap: 20px;
  flex-wrap: wrap;
`

const Radio = styled.input`
  cursor: pointer;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: white;

    &:after {
      display: block;
    }
  }
`

const Container = styled.label`
  display: grid;
  height: 24px;
  align-content: center;
  color: ${(props) => props.theme.colors.darkGray};
  position: relative;
  padding-left: 42px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  flex: auto;

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 23px;
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid ${(props) => props.theme.colors.mediumGray};

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  .checkmark:after {
    top: 6px;
    left: 7px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${(props) => props.theme.colors.primary};
  }
`

export default {
  name: 'radio-button',
  components: {
    Radio,
    ComponentWrapper,
    Container
  },
  props: {
    selectedOption: {
      required: true,
      default: false
    },
    options: {
      required: true,
      default: []
    },
    layout: {
      required: false,
      default: 'vertical' //2 options: 'vertical' (only 1 per row) & 'horizontal' (many per row)
    },
    dataId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      radioName: ''
    }
  },
  methods: {
    onInputHandler(value) {
      this.$emit('on-radio-change', value)
    }
  },
  created() {
    this.radioName = Math.round(Math.random() * 10000)
  }
}
</script>
