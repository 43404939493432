<template>
  <page-wrapper
    :isOpen="isOpen"
    :class="{ visible: isOpen, hidden: !isOpen }"
    @click.native="$emit('on-close')"
  >
    <header-component>
      <image-wrapper>
        <logo :src="companyLogo" :align="align" v-if="!getIsEmbedded" />
        <logo :align="align" v-else />
      </image-wrapper>
    </header-component>
    <modal-container @click.stop>
      <close-button @click.native="$emit('on-close')" class="close" />
      <slot />
    </modal-container>
  </page-wrapper>
</template>

<script>
// To use:
// <modal :isOpen="isOpen" @on-close="$emit('on-close-summary')">
//   <div>Data....</div>
// </modal>

import styled from 'vue-styled-components'
import CloseButton from '@/components/reusable-components/CloseButton.vue'
import companyImg from '@/assets/icons/eturnity_logo.png'
import { mapGetters } from 'vuex'

const HeaderComponent = styled.div`
  height: 54px;
  width: 100%;
  background-color: white;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    height: 40px;
  }
`

const logoAttrs = { align: String }
const ImageWrapper = styled('div', logoAttrs)`
  padding: 12px 40px;
  text-align: ${(props) => (props.align === 'left' ? 'left' : 'right')};
  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    padding: 12px 20px;
  }
`

const Logo = styled.img`
  height: 30px;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    height: 24px;
  }
`

const pageAttrs = { isOpen: Boolean }
const PageWrapper = styled('div', pageAttrs)`
  position: fixed;
  display: grid;
  grid-template-rows: auto 1fr;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  overflow: auto;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    background: white;
  }
`

const ModalContainer = styled.div`
  align-self: center;
  justify-self: center;
  position: relative;
  box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: white;
  margin: 0 auto;
  overflow: auto;
  max-width: 600px;
  max-height: 95%;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;

    @media (max-width: ${(props) => props.theme.screen.mobile}) {
      right: 14px;
      top: 12px;
      background: white;
      position: fixed;
      z-index: 99;
    }
  }

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
`

export default {
  name: 'modal',
  components: {
    PageWrapper,
    ModalContainer,
    CloseButton,
    HeaderComponent,
    Logo,
    ImageWrapper
  },
  props: {
    isOpen: {
      required: true,
      default: false
    }
  },
  data() {
    return {
      companyLogo: null,
      align: 'left'
    }
  },
  computed: {
    ...mapGetters({
      companySettings: 'getCompanySettings',
      getIsEmbedded: 'getIsEmbedded'
    })
  },
  watch: {
    isOpen: function (newVal) {
      document.body.style.overflow = newVal ? 'hidden' : ''
    }
  },
  created() {
    this.companyLogo = this.companySettings.calculator_configuration
      .variation_point_definitions.companyLogo
      ? this.companySettings.calculator_configuration
          .variation_point_definitions.companyLogo
      : companyImg

    this.align = this.companySettings.calculator_configuration.logo_position
      ? this.companySettings.calculator_configuration.logo_position
      : 'left'
  }
}
</script>
