var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ThemeProvider',{style:({ height: '100%' }),attrs:{"theme":_vm.getTheme()}},[(!_vm.settingsLoading && !_vm.translationsLoading && !!_vm.companyToken)?_c('page-container',{attrs:{"isEmbedded":_vm.getIsEmbedded}},[(!_vm.getIsEmbedded)?_c('header-component',{attrs:{"companyLogo":_vm.logo.src,"align":_vm.logo.align}}):_vm._e(),(!_vm.activeQuestion && !_vm.summaryInfo && !_vm.summaryLoading)?_c('home-page'):_vm._e(),(!!_vm.activeQuestion)?_c('question-page'):_vm._e(),(!!_vm.summaryInfo)?_c('summary-page'):_vm._e(),_c('notification'),(_vm.showAcceptCookies && !_vm.getIsEmbedded)?_c('accept-cookies'):_vm._e(),(_vm.$screen.width > 950 && !_vm.getIsEmbedded)?_c('footer-component'):_vm._e()],1):_vm._e(),(
      _vm.settingsLoading ||
      _vm.questionLoading ||
      _vm.summaryLoading ||
      _vm.translationsLoading ||
      !_vm.companyToken
    )?_c('spinner',{attrs:{"fullWidth":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }