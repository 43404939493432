<template>
  <modal :isOpen="isOpen" @on-close="onCloseModal()">
    <form-container>
      <title-container>
        <question-title :questionText="translate('request_title')" />
      </title-container>
      <subtitle-container
        v-if="translate('request_title_subtext') !== 'request_title_subtext'"
        v-html="translate('request_title_subtext')"
      />
      <row-wrapper
        :numCols="2"
        v-if="
          companySettings.calculator_configuration.request_ask_customer_type ===
          'on'
        "
      >
        <radio-button
          dataId="radio_pdf_summary_customer_type"
          :options="getCustomerType()"
          :selectedOption="form.customer_type"
          @on-radio-change="
            onInputChange({ value: $event, type: 'customer_type' })
          "
          layout="horizontal"
        />
        <input-component
          :placeholder="translate('company_name')"
          :value="form.company_name"
          dataId="input_pdf_summary_company_name"
          @input-change="onInputChange({ value: $event, type: 'company_name' })"
          v-show="form.customer_type === 'company'"
        />
      </row-wrapper>
      <row-wrapper :numCols="2">
        <input-component
          :placeholder="
            translate('first_name') +
            ` ${
              companySettings.calculator_configuration
                .request_name_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.first_name"
          @input-change="onInputChange({ value: $event, type: 'first_name' })"
          :isError="
            checkErrors &&
            companySettings.calculator_configuration.request_name_compulsory ===
              'on' &&
            !form.first_name.length
          "
          dataId="input_pdf_summary_first_name"
        />
        <input-component
          :placeholder="
            translate('last_name') +
            ` ${
              companySettings.calculator_configuration
                .request_name_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.last_name"
          @input-change="onInputChange({ value: $event, type: 'last_name' })"
          :isError="
            checkErrors &&
            companySettings.calculator_configuration.request_name_compulsory ===
              'on' &&
            !form.last_name.length
          "
          dataId="input_pdf_summary_last_name"
        />
      </row-wrapper>
      <radio-wrapper v-if="showGenderOptions">
        <radio-button
          :options="getGenderType()"
          :selectedOption="form.gender"
          dataId="radio_pdf_summary_gender"
          @on-radio-change="onInputChange({ value: $event, type: 'gender' })"
          layout="horizontal"
        />
      </radio-wrapper>
      <row-wrapper :numCols="2">
        <input-component
          :placeholder="
            translate('street') +
            ` ${
              companySettings.calculator_configuration
                .request_address_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.route"
          @input-change="onInputChange({ value: $event, type: 'route' })"
          :isError="
            checkErrors &&
            companySettings.calculator_configuration
              .request_address_compulsory === 'on' &&
            !form.route.length
          "
          dataId="input_pdf_summary_street"
        />
        <input-component
          :placeholder="
            translate('house_number') +
            ` ${
              companySettings.calculator_configuration
                .request_address_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.street_number"
          @input-change="
            onInputChange({ value: $event, type: 'street_number' })
          "
          :isError="
            checkErrors &&
            companySettings.calculator_configuration
              .request_address_compulsory === 'on' &&
            !form.street_number.length
          "
          dataId="input_pdf_summary_house_no"
        />
      </row-wrapper>
      <row-wrapper :numCols="2">
        <input-component
          :placeholder="
            translate('postal_code') +
            ` ${
              companySettings.calculator_configuration
                .request_address_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.postal_code"
          @input-change="onInputChange({ value: $event, type: 'postal_code' })"
          :isError="
            checkErrors &&
            companySettings.calculator_configuration
              .request_address_compulsory === 'on' &&
            !form.postal_code.length
          "
          dataId="input_pdf_summary_postal_code"
        />
        <input-component
          :placeholder="
            translate('city') +
            ` ${
              companySettings.calculator_configuration
                .request_address_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.locality"
          @input-change="onInputChange({ value: $event, type: 'locality' })"
          :isError="
            checkErrors &&
            companySettings.calculator_configuration
              .request_address_compulsory === 'on' &&
            !form.locality.length
          "
          dataId="input_pdf_summary_city"
        />
      </row-wrapper>
      <row-wrapper :numCols="2">
        <input-component
          :placeholder="
            translate('email_address') +
            ` ${
              companySettings.calculator_configuration
                .request_email_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.email_address"
          @input-change="
            onInputChange({ value: $event, type: 'email_address' })
          "
          :isError="checkErrors && displayEmailError"
          :errorMessage="emailErrorMessage"
          dataId="input_pdf_summary_email"
        />
        <input-component
          :placeholder="
            translate('phone') +
            ` ${
              companySettings.calculator_configuration
                .request_phone_number_compulsory === 'on'
                ? '*'
                : ''
            }`
          "
          :value="form.phone_number"
          @input-change="onInputChange({ value: $event, type: 'phone_number' })"
          :isError="
            checkErrors &&
            companySettings.calculator_configuration
              .request_phone_number_compulsory === 'on' &&
            !form.phone_number.length
          "
          dataId="input_pdf_summary_phone"
        />
      </row-wrapper>
      <section-title
        v-if="getSystems().length"
        data-id="label_pdf_summary_im_also_interested"
      >
        {{ translate('interests') }}
      </section-title>
      <toggle-container v-if="getSystems().length">
        <toggle
          v-for="item in getSystems()"
          :key="item.value"
          @on-toggle-change="onInputChange({ value: $event, type: item.value })"
          :isChecked="form[item.value]"
          :label="item.label"
          :dataId="`switch_pdf_summary_technology_${item.value}`"
        />
      </toggle-container>
      <text-area-component
        :placeholder="translate('comments')"
        @input-change="onInputChange({ value: $event, type: 'note' })"
        :value="form.note"
        dataId="input_pdf_summary_your_message"
      />
      <terms-container v-for="(toggle, index) in customToggles" :key="index">
        <toggle
          @on-toggle-change="
            toggle.answers.boolean_field.value =
              !toggle.answers.boolean_field.value
          "
          :isChecked="toggle.answers.boolean_field.value"
          :label="toggle.caption + (toggle.required ? ' *' : '')"
        />
      </terms-container>
      <terms-container
        v-if="
          companySettings.calculator_configuration
            .request_data_acceptance_checkbox_show === 'show'
        "
      >
        <toggle
          @on-toggle-change="toggleTerms($event)"
          dataId="switch_pdf_summary_data_processing_consent"
          :isChecked="termsAccepted"
          :label="translate('allow_personal_details')"
        />
      </terms-container>
      <terms-container
        v-if="
          companySettings.calculator_configuration
            .request_newsletter_checkbox_show === 'show'
        "
      >
        <toggle
          @on-toggle-change="toggleNewsletter($event)"
          dataId="switch_pdf_summary_newsletter"
          :isChecked="form.interest_newsletter"
          :label="translate('newsletter_subscribe')"
        />
      </terms-container>
      <button-container
        @mouseenter.native="onSubmitHover(true)"
        @mouseleave.native="onSubmitHover(false)"
      >
        <data-text v-if="showDataText && termsErrorMessage">
          {{ termsErrorMessage }}
        </data-text>
        <button-component
          type="primary"
          dataId="button_pdf_summary_send"
          :text="translate('send_button')"
          @click.native="onSubmit()"
          :disabled="Boolean(termsErrorMessage)"
        />
        <link-text :href="privacyLink" target="blank" v-if="privacyLink">{{
          translate('data_protection')
        }}</link-text>
      </button-container>
      <bottomtext-container
        v-if="translate('request_bottomtext') !== 'request_bottomtext'"
        v-html="translate('request_bottomtext')"
      />
    </form-container>
  </modal>
</template>

<script>
import Modal from '@/components/reusable-components/Modal'
import { mapGetters, mapActions } from 'vuex'
import QuestionTitle from '@/components/reusable-components/QuestionTitle'
import RadioButton from '@/components/reusable-components/RadioButton'
import styled from 'vue-styled-components'
import InputComponent from '@/components/reusable-components/InputComponent'
import ButtonComponent from '@/components/reusable-components/ButtonComponent'
import Toggle from '@/components/reusable-components/Toggle'
import TextAreaComponent from '@/components/reusable-components/TextAreaComponent'

const SubtitleContainer = styled.div`
  margin-bottom: 30px;
`

const BottomtextContainer = styled.div`
  margin-top: 30px;

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
  }
`

const FormContainer = styled.div`
  padding: 50px 30px 30px;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    padding: 0 30px 30px;
  }
`

const rowAttrs = { numCols: Number }
const RowWrapper = styled('div', rowAttrs)`
  display: grid;
  grid-template-columns: ${(props) =>
    props.numCols ? `repeat(${props.numCols}, 1fr)` : '1fr'};
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 22px;
  min-height: 42px;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    grid-template-columns: 1fr;
  }
`

const RadioWrapper = styled(RowWrapper)`
  label {
    margin: 0 8px 8px 0;
  }
`

const TitleContainer = styled.div`
  text-align: center;
`

const LinkText = styled.a`
  color: ${(props) => props.theme.colors.blue};
  font-size: 14px;
  align-self: center;
  justify-self: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #4281a2;
    text-decoration: underline;
  }
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  position: relative;

  a {
    color: ${(props) => props.theme.colors.blue};
    text-decoration: none;
  }
`

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin: 20px 0;
`

const ToggleContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    display: grid;
  }

  div {
    // setting this because of grid/flex issues in safari
    margin-right: 12px;
    margin-bottom: 6px;
  }
`

const TermsContainer = styled.div`
  margin: 30px 0;
`

const DataText = styled.div`
  position: absolute;
  bottom: 65px;
  right: 10%;
  background: #fff;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 16px;
  width: 75%;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  z-index: 99;

  :before {
    content: '';
    background-color: #fff;
    position: absolute;
    bottom: -12px;
    right: 40%;
    height: 8px;
    width: 8px;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    border-width: 1px 0 0 1px;
    transform-origin: center center;
    transform: translate(-2em, -0.5em) rotate(-135deg);
  }
`

export default {
  name: 'summary-request',
  components: {
    SubtitleContainer,
    BottomtextContainer,
    Modal,
    FormContainer,
    QuestionTitle,
    RowWrapper,
    RadioButton,
    TitleContainer,
    InputComponent,
    ButtonComponent,
    ButtonContainer,
    Toggle,
    SectionTitle,
    ToggleContainer,
    TextAreaComponent,
    TermsContainer,
    LinkText,
    DataText,
    RadioWrapper
  },
  props: {
    isOpen: {
      required: true,
      default: false
    }
  },
  data() {
    return {
      form: {
        customer_type: 'company',
        gender: null,
        company_name: '',
        first_name: '',
        last_name: '',
        route: '',
        street_number: '',
        postal_code: '',
        locality: '',
        email_address: '',
        phone_number: '',
        interest_pv_system: false,
        interest_battery: false,
        interest_heat_pump: false,
        interest_ems: false,
        interest_emobility: false,
        interest_community_solar: false,
        interest_newsletter: false,
        note: ''
      },
      customToggles: [],
      checkErrors: false,
      termsAccepted: false,
      showDataText: false
    }
  },
  methods: {
    ...mapActions({
      submitPdf: 'postPdfRequest'
    }),
    toggleTerms() {
      this.termsAccepted = !this.termsAccepted
    },
    toggleNewsletter() {
      this.form.interest_newsletter = !this.form.interest_newsletter
    },
    onSubmitHover(value) {
      this.showDataText = value
    },
    getCustomerType() {
      const type = [
        {
          label: this.translate('private'),
          value: 'private'
        },
        {
          label: this.translate('company'),
          value: 'company'
        }
      ]
      return type
    },
    getSystems() {
      let systems = []
      let settings = this.companySettings.calculator_configuration

      if (settings.request_ask_heat_pump === 'show') {
        systems.push({
          label: this.translate('heat_pump'),
          value: 'interest_heat_pump'
        })
      }
      if (settings.request_ask_pv_system === 'show') {
        systems.push({
          label: this.translate('pv_system'),
          value: 'interest_pv_system'
        })
      }
      if (settings.request_ask_battery === 'show') {
        systems.push({
          label: this.translate('battery'),
          value: 'interest_battery'
        })
      }
      if (settings.request_ask_emobility === 'show') {
        systems.push({
          label: this.translate('e_mobility'),
          value: 'interest_emobility'
        })
      }
      if (settings.request_ask_ems === 'show') {
        systems.push({
          label: this.translate('energy_management_system'),
          value: 'interest_ems'
        })
      }
      if (settings.request_ask_community_solar === 'show') {
        systems.push({
          label: this.translate('zev'),
          value: 'interest_community_solar'
        })
      }
      return systems
    },
    getGenderType() {
      const type = [
        { label: this.translate('gender_male'), value: 'male' },
        { label: this.translate('gender_female'), value: 'female' },
        { label: this.translate('gender_none'), value: 'undefined' }
      ]
      return type
    },
    resetForm() {
      this.form.company_name = ''
      this.form.first_name = ''
      this.form.last_name = ''
      this.form.route = ''
      this.form.street_number = ''
      this.form.postal_code = ''
      this.form.locality = ''
      this.form.email_address = ''
      this.form.phone_number = ''
      this.checkErrors = false
      this.form.interest_pv_system = false
      this.form.interest_battery = false
      this.form.interest_heat_pump = false
      this.form.interest_ems = false
      this.form.interest_emobility = false
      this.form.interest_community_solar = false
      this.form.note = ''
      this.termsAccepted =
        this.companySettings.calculator_configuration
          .request_data_acceptance_checkbox_default_value === 'on'
    },
    onInputChange({ value, type }) {
      this.form[type] = value

      if (type === 'customer_type') {
        // we want to reset the company name if the customer_type is changed
        this.form.company_name = ''
      }
    },
    onCloseModal() {
      this.$emit('on-close-summary')
      this.resetForm()
    },
    onSummarySubmit() {
      this.$emit('on-summary-submitted')
      this.resetForm()
    },
    formErrors() {
      if (
        this.companySettings.calculator_configuration
          .request_name_compulsory === 'on' &&
        !this.form.first_name.length
      ) {
        return true
      } else if (
        this.companySettings.calculator_configuration
          .request_name_compulsory === 'on' &&
        !this.form.last_name.length
      ) {
        return true
      } else if (
        this.companySettings.calculator_configuration
          .request_address_compulsory === 'on' &&
        !this.form.route.length
      ) {
        return true
      } else if (
        this.companySettings.calculator_configuration
          .request_address_compulsory === 'on' &&
        !this.form.street_number.length
      ) {
        return true
      } else if (
        this.companySettings.calculator_configuration
          .request_address_compulsory === 'on' &&
        !this.form.postal_code.length
      ) {
        return true
      } else if (
        this.companySettings.calculator_configuration
          .request_address_compulsory === 'on' &&
        !this.form.locality.length
      ) {
        return true
      } else if (this.displayEmailError) {
        return true
      } else if (
        this.companySettings.calculator_configuration
          .request_phone_number_compulsory === 'on' &&
        !this.form.phone_number.length
      ) {
        return true
      } else {
        return false
      }
    },
    isEmailInvalid(str) {
      const regex =
        /^[a-zA-Z0-9-_%+=*&^|$#!`'{}]+([a-zA-Z0-9-_%+=*&^|$#!`'{}]*\.[a-zA-Z0-9-_%+=*&^|$#!`'{}]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/
      return !regex.test(str)
    },
    onSubmit() {
      if (this.formErrors() || this.termsErrorMessage) {
        this.checkErrors = true
        return
      } else {
        this.checkErrors = false
        this.submitPdf({ form: this.form, customToggles: this.customToggles })
        // Google Analytics tracking below
        let event = {
          name: 'send_request_button',
          category: 'Consulting Request',
          action: 'Visited',
          description: 'Clicked on send request button'
        }

        this.analyticsTracking({ event })
      }
    }
  },
  computed: {
    ...mapGetters({
      companySettings: 'getCompanySettings',
      pdfRequesting: 'getPdfRequestLoading',
      customQuestionsRequest: 'getCustomQuestionsRequest',
      privacyLink: 'getPrivacyLink'
    }),
    emailErrorMessage() {
      return !this.form.email_address.length
        ? ''
        : this.translate('invalid_email_address')
    },
    displayEmailError() {
      return (
        this.isEmailInvalid(this.form.email_address) ||
        (this.companySettings.calculator_configuration
          .request_email_compulsory === 'on' &&
          !this.form.email_address.length)
      )
    },
    termsErrorMessage() {
      const dataAcceptanceAccepted =
        this.termsAccepted ||
        this.companySettings.calculator_configuration
          .request_data_acceptance_checkbox_show === 'hide'
      const requiredCustomTogglesAccepted = this.customToggles.every(
        (toggle) => !toggle.required || toggle.answers.boolean_field.value
      )
      return !dataAcceptanceAccepted
        ? this.translate('data_processing_permission')
        : !requiredCustomTogglesAccepted
        ? this.translate('accept_all_custom_terms')
        : ''
    },
    showGenderOptions() {
      return (
        (this.companySettings.calculator_configuration.request_ask_gender &&
          this.form.customer_type !== 'company') ||
        (this.companySettings.calculator_configuration
          .request_ask_customer_type === 'off' &&
          this.companySettings.calculator_configuration.request_ask_gender)
      )
    }
  },
  created() {
    // setting genderType on create, because if it's false, then we don't want to set anything
    this.form.gender = this.companySettings.calculator_configuration
      .request_ask_gender
      ? 'male'
      : null

    this.termsAccepted =
      this.companySettings.calculator_configuration
        .request_data_acceptance_checkbox_default_value === 'on'

    this.form.interest_newsletter =
      this.companySettings.calculator_configuration
        .request_newsletter_checkbox_default_value === 'on'

    this.customToggles = this.customQuestionsRequest
  },
  watch: {
    pdfRequesting: function (newVal, oldVal) {
      // We should close the modal after a success
      if (oldVal && !newVal) {
        this.onSummarySubmit()
      }
    }
  }
}
</script>
