<template>
  <ThemeProvider :theme="getTheme()" :style="{ height: '100%' }">
    <page-container
      v-if="!settingsLoading && !translationsLoading && !!companyToken"
      :isEmbedded="getIsEmbedded"
    >
      <header-component
        :companyLogo="logo.src"
        :align="logo.align"
        v-if="!getIsEmbedded"
      />
      <home-page v-if="!activeQuestion && !summaryInfo && !summaryLoading" />
      <question-page v-if="!!activeQuestion" />
      <summary-page v-if="!!summaryInfo" />
      <notification />
      <accept-cookies v-if="showAcceptCookies && !getIsEmbedded" />
      <footer-component v-if="$screen.width > 950 && !getIsEmbedded" />
    </page-container>
    <spinner
      v-if="
        settingsLoading ||
        questionLoading ||
        summaryLoading ||
        translationsLoading ||
        !companyToken
      "
      :fullWidth="true"
    />
  </ThemeProvider>
</template>

<script>
import { ThemeProvider } from 'vue-styled-components'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import theme from './assets/theme'
import HeaderComponent from '@/components/HeaderComponent'
import FooterComponent from '@/components/FooterComponent'
import QuestionPage from '@/components/reusable-components/QuestionPage'
import styled from 'vue-styled-components'
import companyImg from '@/assets/icons/eturnity_logo.png'
import HomePage from '@/components/HomePage'
import Spinner from '@/components/reusable-components/Spinner'
import Notification from '@/components/reusable-components/Notification'
import SummaryPage from '@/components/SummaryPage'
import AcceptCookies from '@/components/reusable-components/AcceptCookies'
import tokens from '@/assets/tokens.json'

const pageAttrs = { isEmbedded: Boolean }
const PageContainer = styled('div', pageAttrs)`
  max-width: 1200px;
  margin: ${(props) => (props.isEmbedded ? '0' : '0 auto')};
  padding: ${(props) => (props.isEmbedded ? '0' : '0 40px')};
  height: 100%;

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    margin: 0;
    padding: 0;
  }
`

export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.title
    }
  },
  components: {
    ThemeProvider,
    PageContainer,
    HeaderComponent,
    FooterComponent,
    HomePage,
    Spinner,
    QuestionPage,
    SummaryPage,
    Notification,
    AcceptCookies
  },
  data() {
    return {
      title: this.translate('browser_title'),
      toggleValue: false,
      selectedAnswer: null,
      logo: {
        src: companyImg,
        align: 'left'
      },
      allTokens: tokens
    }
  },
  methods: {
    ...mapActions({
      getCompanySettings: 'getCompanySettings',
      getTranslations: 'getTranslations'
    }),
    ...mapMutations({
      setShowAcceptCookies: 'setShowAcceptCookies',
      setAllowCookies: 'setAllowCookies',
      setCompanyToken: 'setCompanyToken',
      setIsEmbedded: 'setIsEmbedded'
    }),
    onInputChange(value) {
      this.toggleValue = value
    },
    onAnswerClick(value) {
      this.selectedAnswer = value
    },
    getTheme() {
      if (!this.companySettings) {
        return
      }
      let custom =
        this.companySettings.calculator_configuration
          .variation_point_definitions.styles
      let myTheme = theme
      if (!!custom) {
        myTheme.colors.primary = custom.colors.primary
          ? custom.colors.primary
          : myTheme.colors.primary

        myTheme.hasCustomPrimary =
          !!custom.colors.primary && custom.colors.primary.length

        myTheme.colors.secondary = custom.colors.secondary
          ? custom.colors.secondary
          : myTheme.colors.secondary

        myTheme.colors.tertiary = custom.colors.tertiary
          ? custom.colors.tertiary
          : myTheme.colors.tertiary

        myTheme.borderRadius = custom.borderRadius
          ? custom.borderRadius
          : myTheme.borderRadius

        if (custom.fontStyle) {
          // if a company has a custom font, then we want to override it
          document.body.style.fontFamily = custom.fontStyle
        }
      }
      return myTheme
    },
    initialSettings() {
      this.getCompanySettings()
      this.getTranslations()
      this.setShowAcceptCookies(!this.$cookies.isKey('allowCookies')) // if the key doesn't exist, show the cookie message
      this.setAllowCookies(
        this.$cookies.isKey('allowCookies') &&
          this.$cookies.get('allowCookies') === 'true'
      )
    }
  },
  computed: {
    ...mapGetters({
      companySettings: 'getCompanySettings',
      settingsLoading: 'getSettingsLoading',
      activeQuestion: 'getActiveQuestion',
      questionLoading: 'getQuestionLoading',
      summaryInfo: 'getSummaryInfo',
      summaryLoading: 'getSummaryLoading',
      showAcceptCookies: 'getShowAcceptCookies',
      translationsLoading: 'getTranslationsLoading',
      companyToken: 'getCompanyToken',
      getIsEmbedded: 'getIsEmbedded'
    }),
    translationsLoaded() {
      return !!localStorage.getItem('translations')
    }
  },
  created() {
    const path = location.pathname
      .replace(/\/configurator/g, '')
      .replace(/\//g, '')
    let token = this.allTokens.companies[path]
    this.setCompanyToken(token)
    const urlParams = new URLSearchParams(window.location.search)
    let isEmbedded = urlParams.get('embedded')
    isEmbedded = isEmbedded === 'true' ? true : false
    this.setIsEmbedded(isEmbedded)
  },
  mounted() {
    setTimeout(() => {
      // Need this timeout to set the language from the main.js
      const companyName = localStorage.getItem('company_name')
      const path = location.pathname
        .replace(/\/configurator/g, '')
        .replace(/\//g, '')

      if (companyName && companyName !== path) {
        sessionStorage.clear()
        localStorage.clear()
        localStorage.setItem('company_name', path)
        location.reload()
      } else {
        localStorage.setItem('company_name', path)
      }

      if (localStorage.getItem('lang')) {
        this.initialSettings()
      } else {
        // If the first call takes longer to load to set the lang, then we wait a little longer
        setTimeout(() => {
          this.initialSettings()
        }, 2000)
        this.initialSettings()
      }
    }, 1500)
  },
  watch: {
    companySettings: function (newVal) {
      // We should close the modal after a success
      if (newVal) {
        const custom =
          newVal.calculator_configuration.variation_point_definitions
        this.getTheme()
        this.logo.align = newVal.calculator_configuration.logo_position
        if (custom && custom.companyLogo) {
          this.logo.src = custom.companyLogo
        }
      }
    },
    translationsLoading: function (newVal) {
      if (!newVal) {
        this.title = this.translate('browser_title')
      }
    }
  }
}
</script>
